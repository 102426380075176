import { Contentity } from "@/db/parade/contentities/schema"
import type { Asset, ContentItem } from "@/schemas/content-item-extended.schema"
import { RawDbContentItem } from "@/schemas/content-item-rawdb.schema"
import type { SimplifiedContentItem } from "@/schemas/content-item-simplified.schema"

export function hasVideo(contentItem: SimplifiedContentItem | ContentItem | Contentity): boolean {
    return (
        contentItem.content[0] !== undefined &&
        contentItem.content[0].content.videos !== undefined &&
        contentItem.content[0].content.videos.length > 0 &&
        contentItem.content[0].content.videos[0] !== undefined &&
        contentItem.content[0].content.videos[0].uri !== undefined
    )
}

export function isVideoContentItem(contentItem: SimplifiedContentItem | ContentItem | Contentity): boolean {
    return (
        contentItem.content[0] !== undefined &&
        contentItem.content[0].content.videos !== undefined &&
        contentItem.content[0].content.videos.length > 0 &&
        contentItem.content[0].content.videos[0] !== undefined &&
        contentItem.content[0].content.videos[0].uri !== undefined
    )
}

export function hasImage(contentItem: SimplifiedContentItem | ContentItem | Contentity): boolean {
    return (
        contentItem.content[0] !== undefined &&
        contentItem.content[0].content.images !== undefined &&
        contentItem.content[0].content.images.length > 0 &&
        contentItem.content[0].content.images[0] !== undefined &&
        contentItem.content[0].content.images[0].uri !== undefined
    )
}

export function getFirstImageAssetForContentItem(
    contentItem: SimplifiedContentItem | ContentItem | Contentity | undefined
): Asset | undefined {
    if (!contentItem) {
        return undefined
    }

    // we return either the video thumbnail or the first image in the content item:
    if (hasVideo(contentItem) && contentItem.content[0]?.content.videos![0]?.thumbnail !== undefined) {
        return {
            uri: contentItem.content[0].content.videos[0].thumbnail ?? undefined
        } as Asset
    }

    if (hasImage(contentItem)) {
        return contentItem.content[0]?.content.images![0] ?? undefined
    }

    return undefined
}

export function getFirstImageAssetForRawContentItem(
    contentItem: RawDbContentItem | undefined
): Asset | undefined {
    if (!contentItem) {
        return undefined
    }

    // First try to return the image from the content item:
    if (contentItem.content_set.images && contentItem.content_set.images.length > 0) {
        return contentItem.content_set.images[0]
    }

    // Try video thumbnail:
    if (
        contentItem.content_set.videos &&
        contentItem.content_set.videos.length > 0 &&
        contentItem.content_set.videos[0]?.still_image !== undefined
    ) {
        return {
            uri: contentItem.content_set.videos[0].still_image ?? undefined
        } as Asset
    }

    return undefined
}
