"use client"

import Link from "next/link"
import DateFormatter from "@/components/common/date-formatter"
import { ContentItemImage } from "@/components/features/content-item/content-item-image"
import { getFirstImageAssetForContentItem, isVideoContentItem } from "@/lib/content-item/assets"
import { truncateText } from "@/lib/utils"
import { type SimplifiedContentItem } from "@/schemas/content-item-simplified.schema"
import { getContentForLanguage } from "@/lib/content-item/languages"
import TCELogo from "@/components/icons/tce-logo"
import { Carousel, type CarouselApi, CarouselContent, CarouselItem } from "@/components/ui/carousel"
import { useState } from "react"
import { ChevronLeft, ChevronRight, PlaySquareIcon } from "lucide-react"

function PlayIcon() {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            className="text-tce-800"
        >
            <circle cx="32" cy="32" r="30" className="fill-current" />
            <polygon points="27,20 45,32 27,44" fill="white" />
        </svg>
    )
}

export type ContentCollection = {
    id: string
    name: string
    contentItems: SimplifiedContentItem[]
}

function PublisherOverlay({ name }: { name: string | undefined }) {
    return (
        <div className="absolute top-1.5 left-2 z-10 bg-black/50 px-2 rounded">
            <span className="text-xs font-medium text-white">{name}</span>
        </div>
    )
}

function ContentItemCard({
    item,
    label,
    className
}: {
    item: SimplifiedContentItem
    label?: string
    className?: string
}) {
    const content = getContentForLanguage(item, "en")
    const isVideo = isVideoContentItem(item) || false
    const firstImage = getFirstImageAssetForContentItem(item)

    return (
        <div className={`flex flex-col h-[320px] w-full ${className}`}>
            <div className="flex flex-col h-full overflow-hidden rounded-lg border-2 bg-gray-50 dark:bg-gray-900 shadow-lg ring-1 ring-inset ring-gray-200 dark:ring-gray-800 transition-shadow duration-200 ease-in-out hover:border-orange-600 hover:bg-gray-100 dark:hover:bg-gray-800 hover:shadow-xl">
                <Link href={`/content/${item.id}`} className="flex flex-col h-full">
                    <div className="h-48 w-full flex-shrink-0 relative">
                        {firstImage && (
                            <>
                                <PublisherOverlay
                                    name={
                                        item.organisation.name === label ? undefined : item.organisation.name
                                    }
                                />
                                <ContentItemImage image={firstImage} />
                            </>
                        )}
                        {!firstImage && isVideo && (
                            <div className="h-full w-full bg-gray-300 dark:bg-gray-700 object-cover flex items-center justify-center">
                                <PublisherOverlay name={item.organisation.name} />
                                <PlayIcon />
                            </div>
                        )}
                        {!firstImage && !isVideo && (
                            <div className="h-full w-full bg-gray-300 dark:bg-gray-700 object-cover flex items-center justify-center">
                                <PublisherOverlay name={item.organisation.name} />
                                <TCELogo nolink />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col flex-1 overflow-y-auto">
                        <div className="flex justify-end items-center gap-1 pr-1 pt-2 sticky top-0 bg-gray-50 dark:bg-gray-900 z-10">
                            {isVideo && (
                                <div className="mr-auto pl-2">
                                    <PlaySquareIcon className="h-4 w-4 text-tce-800 dark:text-tce-200" />
                                </div>
                            )}
                            <p className="text-xs text-tce-800 dark:text-tce-200">
                                {item.createdAt && <DateFormatter date={item.createdAt} />}
                            </p>
                        </div>
                        <h3 className="py-1 pl-2 pr-1 text-sm font-medium text-gray-900 dark:text-gray-100">
                            {truncateText(content?.content.headline, 80)}
                        </h3>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export function ContentItemCards({ items, header }: { items: SimplifiedContentItem[]; header?: string }) {
    const [api, setApi] = useState<CarouselApi>()

    return (
        <div className="w-full px-0">
            <Carousel
                opts={{
                    align: "start",
                    dragFree: true,
                    loop: true
                }}
                setApi={setApi}
                className="w-full"
            >
                <CarouselContent className="-ml-2 md:-ml-4">
                    {items?.map((item) => (
                        <CarouselItem
                            key={item.id}
                            className="pl-2 md:pl-4 basis-1/2 md:basis-1/3 lg:basis-1/5 xl:basis-1/6"
                        >
                            <ContentItemCard item={item} label={header} />
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <div className="flex items-center">
                    <button
                        onClick={() => api?.scrollPrev()}
                        className="h-10 w-10 rounded-full bg-gradient-to-r from-tce-600 to-tce-400 hover:from-tce-700 hover:to-tce-500 border-none text-white hover:text-white flex items-center justify-center absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-5"
                    >
                        <ChevronLeft className="h-6 w-6" />
                    </button>
                    <button
                        onClick={() => api?.scrollNext()}
                        className="h-10 w-10 rounded-full bg-gradient-to-r from-tce-600 to-tce-400 hover:from-tce-700 hover:to-tce-500 border-none text-white hover:text-white flex items-center justify-center absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-5"
                    >
                        <ChevronRight className="h-6 w-6" />
                    </button>
                </div>
            </Carousel>
        </div>
    )
}

export function ContentItemCardsGrid({ items }: { items: SimplifiedContentItem[] }) {
    return (
        <ul className="grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
            {items?.map((item, index) => (
                <ContentItemCard
                    key={item.id}
                    item={item}
                    className={index === 5 && items.length <= 6 ? " lg:hidden xl:block" : ""}
                />
            ))}
        </ul>
    )
}
